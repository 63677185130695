import axios from "axios";


interface Session {
    user: {
        id: string;
    };
}

export async function fetchImage(session: Session): Promise<string | null> {
    
    try {
        const url = `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/profiles/avatar/${session.user.id}`;
        const response = await axios.get<{ status: string; data: { url: string } }>(
           url,
            {
                headers: {
                    "authorization": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibGthLWRldiIsImlhdCI6MTcxMDU1Mjg3NX0.07LNi_mIA7gn3wRYt6xpw8L7YQoSV1IMEJqaV66EoMk`
                }
            }
        );
        if (response.data.status === 'success' && response.data.data && response.data.data.url) {
            return response.data.data.url;
        } else {
            console.error('Invalid response:', response);
            return null;
        }
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
}

export async function uploadImage(session: Session, formData: FormData): Promise<void> {
    try {
        await axios.post(
            `${process.env.NEXT_PUBLIC_BACKEND_URL}/api/v1/profiles/avatar/${session.user.id}/update`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "authorization": `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibGthLWRldiIsImlhdCI6MTcxMDU1Mjg3NX0.07LNi_mIA7gn3wRYt6xpw8L7YQoSV1IMEJqaV66EoMk`
                },
            }
        );
    } catch (error) {
        console.error('Error uploading image:', error);
        throw error;
    }
}
