"use client";

import Image from "next/image";
import { IoPlaySkipBackOutline, IoPlaySkipForwardOutline, IoShuffle } from "react-icons/io5";
import { TiArrowLoop } from "react-icons/ti";
import { CiPlay1, CiPause1 } from "react-icons/ci";
import { SlVolume2, SlVolumeOff } from "react-icons/sl"; 

import { useAudio } from "./AudioContext";
import { Button } from "@/components/ui/button";

export default function AudioPlayer() {
    const { togglePlayPause, isPlaying, mute, unmute, isMuted, volume, setVolume, currentTime, duration, handleSeek } = useAudio(); 

    const changeRange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newTime = parseFloat(e.target.value);
        handleSeek(newTime); // Update current time and seek
    };

    const calculateTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`; // Format time
    };

    return (
        <div className="w-full h-20 z-20 fixed bottom-0 left-0 bg-company-gray shadow-xl px-8">
            <div className="max-w-[1400px] m-auto">
                <div className="grid grid-cols-3">
                    <div className="flex py-4">
                        <Image className="my-auto" src="/musicplayer/cover.png" width={48} height={48} alt="Cover-Picture" />
                        <div className="px-4 items-center my-auto">
                            <p className="text-company-green">Song-title</p>
                            <p className="text-sm text-white opacity-50">Interpret</p>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 m-auto">
                        <div className="flex text-white">
                            <Button  className="text-2xl text-start opacity-50"><IoShuffle /></Button>
                            <div className="flex m-auto gap-4">
                                <Button  className="text-2xl"><IoPlaySkipBackOutline /></Button>
                                <Button  className="text-4xl" onClick={togglePlayPause}>
                                    {isPlaying ? <CiPause1 /> : <CiPlay1 />}
                                </Button>
                                <Button className="text-2xl"><IoPlaySkipForwardOutline /></Button>
                            </div>
                            <Button  className="text-2xl opacity-50"><TiArrowLoop /></Button>
                        </div>
                        
                        <div className="flex text-white m-auto">
                            <div className="text-xs pt-2 opacity-50">{calculateTime(currentTime)}</div>
                            <div className="px-3">
                                <input
                                    type="range"
                                    min="0"
                                    max={duration}
                                    value={currentTime}
                                    className="w-96 mb-4 h-[2px] rounded-lg appearance-none cursor-pointer opacity-50"
                                    onChange={changeRange}
                                />
                            </div>
                            <div className="text-xs pt-2 opacity-50">{calculateTime(duration)}</div>
                        </div>
                    </div>

                    <div className="flex gap-4 justify-end text-white opacity-50">
                        <button className="text-xl" onClick={isMuted ? unmute : mute}>
                            {isMuted ? <SlVolumeOff /> : <SlVolume2 />}
                        </button>
                        <input
                            type="range"
                            className="w-32 my-auto h-[2px] rounded-lg appearance-none cursor-pointer"
                            min="0"
                            max="1"
                            step="0.01"
                            value={isMuted ? 0 : volume}
                            onChange={(e) => setVolume(parseFloat(e.target.value))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
