"use client";

import { Button } from "@/components/ui/button"
import Link from "next/link";


export function LoginDialog() {
    

    return (
      
        <Link href="/login">
          <Button className="outline outline-1 outline-company-green text-company-green focus:outline-company-green">Log In</Button>
        </Link>
        
    )
  }
  

