"use client";

import React, { createContext, useContext, useState, useRef, useEffect } from "react";
import ReactHowler from "react-howler";

interface AudioContextType {
    isPlaying: boolean;
    togglePlayPause: () => void;
    setSource: (source: string) => void;
    volume: number;
    setVolume: (volume: number) => void;
    isMuted: boolean;
    mute: () => void;
    unmute: () => void;
    playerRef: React.RefObject<ReactHowler | null>;
    currentTime: number;
    duration: number;
    setDuration: (duration: number) => void;
    handleSeek: (time: number) => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

export const useAudio = () => {
    const context = useContext(AudioContext);
    if (!context) {
        throw new Error("useAudio must be used within an AudioProvider");
    }
    return context;
};

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [source, setSource] = useState<string>(" ");
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    const playerRef = useRef<ReactHowler | null>(null);

    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    const mute = () => {
        setVolume(0);
        setIsMuted(true);
    };

    const unmute = () => {
        setVolume(1);
        setIsMuted(false);
    };

    const handleLoad = () => {
        setCurrentTime(0);
    };

    const handleSeek = (time: number) => {
        setCurrentTime(time);
        if (playerRef.current) {
            playerRef.current.seek(time);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPlaying && playerRef.current) {
                setCurrentTime(playerRef.current.seek());
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [isPlaying]);

    const value = {
        isPlaying,
        togglePlayPause,
        setSource,
        volume,
        setVolume,
        isMuted,
        mute,
        unmute,
        playerRef,
        currentTime,
        duration,
        setDuration,
        handleSeek,
    };

    return (
        <AudioContext.Provider value={value}>
            <ReactHowler
                src={source}
                playing={isPlaying}
                volume={isMuted ? 0 : volume}
                ref={playerRef}
                onLoad={() => {
                    handleLoad();
                    if (playerRef.current) {
                        const audioDuration = playerRef.current.duration();
                        setDuration(audioDuration);
                    }
                }}
                onEnd={() => {
                    setIsPlaying(false); // Automatically stop playing when the audio ends
                }}
            />
            {children}
        </AudioContext.Provider>
    );
};
