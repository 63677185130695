"use client";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdLogout } from "react-icons/md";
import Link from "next/link";
import { useEffect, useState } from "react";
import { fetchImage } from "../api/ImageHandling/ImageHandling";


interface LogoutProps {
    session: any; 
    username: string; // Add username prop to the interface
}

const Logout: React.FC<LogoutProps> = ({ session, username }) => {
    const [imageUrl, setImageUrl] = useState<string>('');
   

    useEffect(() => {
        async function fetchImageData() {
            const imageUrl = await fetchImage(session);
            
            if (imageUrl) {
                setImageUrl(imageUrl.toString());
            }
        }
        fetchImageData();
    }, [session]); // Include session in dependencies to re-fetch image if session changes

    const router = useRouter();
    const handleLogout = () => {
        signOut({
            redirect: true,
            callbackUrl: `${window.location.origin}/`
        });
        router.refresh();
    }

    return (
        <div>
            <DropdownMenu>
                <DropdownMenuTrigger className="flex focus:outline-none hover:text-company-green">
                    <Avatar>
                        <AvatarImage src={imageUrl} />
                        <AvatarFallback>C</AvatarFallback>
                    </Avatar>
                    <div className="justify-end my-auto ml-2"><MdKeyboardArrowDown /></div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-24 mt-2 p-2 border border-opacity-10 border-white bg-company-gray text-white text-2xl w-48">
                    <DropdownMenuItem className="flex flex-row text-company-green uppercase text-base">
                        {username}
                    </DropdownMenuItem>
                    <DropdownMenuSeparator />
                    <hr className="mt-2 opacity-50" />
                    <DropdownMenuItem asChild className="mt-2 hover:text-company-green cursor-pointer">
                        <Link href="/profile">Profil</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className="hover:text-company-green cursor-pointer">
                        <Link href="/dashboard">Dashboard</Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem asChild className="hover:text-company-green cursor-pointer">
                        <Link href="/settings">Settings</Link>
                    </DropdownMenuItem>
                    <hr className="mt-2 opacity-50" />
                    <DropdownMenuItem onClick={handleLogout} className="mt-2">
                        <button className="flex flex-row my-auto hover:text-company-green">
                            <div className="my-auto pr-2"><MdLogout /></div>Logout
                        </button>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

export default Logout;
